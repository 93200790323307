<template>
  <div class="row csn-user-edit csn-user-account-section" id="user-account">
    <!-- <div class="col-md-12" id="appUserProfile"></div> -->

    <div class="col-md-12">
      <div class="container csn-user-profile">
        <section>
          <h1 style="color: white;">{{ t('my_profile') }}</h1>
          <p>{{ t('description') }}</p>
        </section>

        <section>
          <Message :type="messageType" :message="message" :hasBottom="true" />
          <Form :ref="formRef">
            <div class="row csn-user-profile-main" v-if="rendersForm">
              <div class="col-md-6">
                <h2>{{ t('personal_data') }}</h2>
                <Dropdown
                  :name="name.SEX"
                  :label="t('salutation_label')"
                  :dataSource="genderList"
                  v-model="formData[name.SEX]"
                  :placeholder="t('salutation_placeholder')"
                  :serverError="serverError[name.SEX]"
                />
                <TextField
                  :type="inputType.TEXT"
                  :name="name.FIRST_NAME"
                  :label="t('first_name_label')"
                  v-model="formData[name.FIRST_NAME]"
                  :serverError="serverError[name.FIRST_NAME]"
                />
                <TextField
                  :type="inputType.TEXT"
                  :name="name.LAST_NAME"
                  :label="t('last_name_label')"
                  v-model="formData[name.LAST_NAME]"
                  :serverError="serverError[name.LAST_NAME]"
                />
                <div class="csn-user-profile-birthdate">
                  <Dropdown
                    :dataSource="dateList"
                    :label="t('date_of_birth_label')"
                    :name="name.BIRTH_DATE"
                    v-model="formData[name.BIRTH_DATE]"
                    :serverError="serverError[name.BIRTH_DATE]"
                  />
                  <Dropdown
                    :dataSource="monthList"
                    :name="name.BIRTH_MONTH"
                    v-model="formData[name.BIRTH_MONTH]"
                  />
                  <Dropdown
                    :dataSource="yearList"
                    :name="name.BIRTH_YEAR"
                    :keepsOrder="true"
                    v-model="formData[name.BIRTH_YEAR]"
                  />
                </div>
                <div class="csn-user-profile-birthdate-error">
                  <Balloon :text="serverError[name.BIRTH_DATE]" />
                </div>
                <Dropdown
                  :dataSource="citizenshipList"
                  :label="t('citizenship_label')"
                  :name="name.CITIZENSHIP"
                  v-model="formData[name.CITIZENSHIP]"
                  :serverError="serverError[name.CITIZENSHIP]"
                />
                <UpdateProfileButton
                  v-if="!isMobileScreen"
                  :isSubmitting="isSubmitting"
                  @click="submitForm"
                />
              </div>
              <div class="col-md-6">
                <h2>{{ t('address') }}</h2>
                <TextField
                  :type="inputType.TEXT"
                  :label="t('street_and_house_number_label')"
                  :name="name.ADDRESS"
                  v-model="formData[name.ADDRESS]"
                  :serverError="serverError[name.ADDRESS]"
                />
                <TextField
                  :type="inputType.TEXT"
                  :label="t('zip_code_label')"
                  :name="name.ZIP_CODE"
                  v-model="formData[name.ZIP_CODE]"
                  :serverError="serverError[name.ZIP_CODE]"
                />
                <TextField
                  :type="inputType.TEXT"
                  :label="t('city_label')"
                  :name="name.CITY"
                  v-model="formData[name.CITY]"
                  :serverError="serverError[name.CITY]"
                />
                <Dropdown
                  :dataSource="citizenshipList"
                  :label="t('country_label')"
                  :name="name.COUNTRY"
                  v-model="formData[name.COUNTRY]"
                  :serverError="serverError[name.COUNTRY]"
                />
                <UpdateProfileButton
                  v-if="isMobileScreen"
                  :isSubmitting="isSubmitting"
                  @click="submitForm"
                />
              </div>
            </div>
            <div class="row csn-user-profile-main" v-else>
              <Loader />
            </div>
          </Form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import {
  USER_EDIT,
  Module,
  PERSONAL_INFO,
  UserInfo,
  REMOVE_FLASH,
  FLASH_DICTIONARY,
  FlashId,
  UPDATE_PERSONAL_INFO,
  Digit,
  MUST_CHANGE_PASSWORD,
  IS_LOGGED,
  IS_EMPTY_USER_ADDRESS,
  RouteName,
  IS_MOBILE_SCREEN,
  FlashType,
  ADD_FLASH,
} from '@/constants'
import { setCalendarList, isNil, dest, navigateTo } from '@/helpers'
import { formMixin } from '@/mixins'
import { AuthApi, MainApi, ExternalApi } from '@/api'

const formData = {
  [UserInfo.SEX]: null,
  [UserInfo.FIRST_NAME]: null,
  [UserInfo.LAST_NAME]: null,
  [UserInfo.ADDRESS]: null,
  [UserInfo.CITY]: null,
  [UserInfo.COUNTRY]: null,
  [UserInfo.ZIP_CODE]: null,
  [UserInfo.CITIZENSHIP]: null,
  [UserInfo.BIRTH_DATE]: null,
  [UserInfo.BIRTH_MONTH]: null,
  [UserInfo.BIRTH_YEAR]: null,
  [UserInfo.STATE]: 'test',
  [UserInfo.BIRTH_COUNTRY]: 'PL',
  [UserInfo.BIRTH_PLACE]: 'test',
}

const DATE_OF_BIRTH = 'date_of_birth'
const currentYear = new Date().getFullYear()
const minAge = currentYear - Digit.EIGHTEEN
const maxAge = minAge - Digit.ONE_HUNDRED

const dateList = setCalendarList({ end: Digit.THIRTY_ONE })
const monthList = setCalendarList({ end: Digit.TWELVE })
const yearList = setCalendarList({ start: minAge, end: maxAge })

export default {
  name: USER_EDIT,
  mixins: [formMixin],
  components: {
    Form: () => import('@/components/FormData'),
    TextField: () => import('@/components/TextField'),
    Dropdown: () => import('@/components/Dropdown'),
    Loader: () => import('@/components/Loader'),
    Balloon: () => import('@/components/Balloon'),
    Message: () => import('@/components/atoms/Message'),
    UpdateProfileButton: () => import('./components/UpdateProfileButton.vue'),
  },
  data: () => ({
    citizenshipList: null,
    formData: { ...formData },
  }),
  computed: {
    ...mapState(Module.USER, [PERSONAL_INFO, MUST_CHANGE_PASSWORD]),
    ...mapState(Module.FLASH, [FLASH_DICTIONARY]),
    ...mapGetters({ isLogged: dest([Module.USER, IS_LOGGED]) }),
    ...mapGetters({
      isEmptyAddress: dest([Module.USER, IS_EMPTY_USER_ADDRESS]),
    }),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    dateList: () => dateList,
    monthList: () => monthList,
    yearList: () => yearList,
    t() {
      return this.$createComponentTranslator(USER_EDIT)
    },
    name: () => UserInfo,
    rendersForm() {
      return /*!!this.formData[this.name.CITIZENSHIP] && */ !!this
        .citizenshipList
    },
    genderList() {
      return [
        { name: this.t('mr'), value: '0' },
        { name: this.t('mrs'), value: '1' },
      ]
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(currentFormData) {
        this.message && (this.message = null)
        this.messageType && (this.messageType = null)

        if (!Object.keys(this.serverError).length) {
          return
        }

        const { BIRTH_DATE, BIRTH_MONTH, BIRTH_YEAR } = this.name

        Object.keys(this.serverError).forEach((key) => {
          if (key !== BIRTH_DATE) {
            if (currentFormData[key] === this.formDataSubmitCopy[key]) {
              return
            }

            const newServerError = { ...this.serverError }
            delete newServerError[key]

            this.serverError = newServerError
            return
          }

          if (
            currentFormData[BIRTH_DATE] ===
              this.formDataSubmitCopy[BIRTH_DATE] &&
            currentFormData[BIRTH_MONTH] ===
              this.formDataSubmitCopy[BIRTH_MONTH] &&
            currentFormData[BIRTH_YEAR] === this.formDataSubmitCopy[BIRTH_YEAR]
          ) {
            return
          }

          const newServerError = { ...this.serverError }
          delete newServerError[BIRTH_DATE]
          this.serverError = newServerError
        })
      },
    },
  },
  methods: {
    ...mapMutations(Module.FLASH, [REMOVE_FLASH, ADD_FLASH]),
    ...mapActions(Module.USER, [UPDATE_PERSONAL_INFO]),
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      this.isSubmitting = true
      const {
        SEX,
        FIRST_NAME,
        LAST_NAME,
        ADDRESS,
        CITY,
        COUNTRY,
        ZIP_CODE,
        CITIZENSHIP,
        BIRTH_DATE,
        BIRTH_MONTH,
        BIRTH_YEAR,
        BIRTH_COUNTRY,
        BIRTH_PLACE,
        STATE,
      } = this.name

      const editData = {
        user_edit: {
          [SEX]: this.formData[SEX],
          [FIRST_NAME]: this.formData[FIRST_NAME],
          [LAST_NAME]: this.formData[LAST_NAME],
          date_of_birth: {
            day: this.formData[BIRTH_DATE],
            month: this.formData[BIRTH_MONTH],
            year: this.formData[BIRTH_YEAR],
          },
          [CITIZENSHIP]: this.formData[CITIZENSHIP],
          [BIRTH_COUNTRY]: this.formData[BIRTH_COUNTRY],
          [BIRTH_PLACE]: this.formData[BIRTH_PLACE],
        },
        user_address: {
          [ADDRESS]: this.formData[ADDRESS],
          [CITY]: this.formData[CITY],
          [ZIP_CODE]: this.formData[ZIP_CODE],
          [COUNTRY]: this.formData[COUNTRY],
          [STATE]: this.formData[STATE],
        },
      }

      try {
        const response = await AuthApi.postUserEdit(editData)

        if (!this.validateResponse(response)) {
          return
        }

        const { state, data, msg } = response

        if (state === this.responseState.ERROR) {
          const serverError = {}

          Object.keys(data.errors || {}).forEach((key) => {
            if (key === DATE_OF_BIRTH) {
              serverError[BIRTH_DATE] = data.errors[DATE_OF_BIRTH].msg
              return
            }

            serverError[key] = data.errors[key].msg
          })

          this.serverError = serverError
          this.formDataSubmitCopy = { ...this.formData }
          return
        }

        this.serverError = {}
        this.formDataSubmitCopy = {}
        await this.UPDATE_PERSONAL_INFO()

        this.messageType = this.messageTypeEnum.SUCCESS
        this.message = msg

        this.FLASH_DICTIONARY[FlashId.INSUFFICIENT_USER_DATA] &&
          this.REMOVE_FLASH({ id: FlashId.INSUFFICIENT_USER_DATA })

        this.FLASH_DICTIONARY[FlashId.LACKS_USER_DATA] &&
          this.REMOVE_FLASH({ id: FlashId.LACKS_USER_DATA })

        navigateTo({ name: RouteName.USER_ACCOUNT })
      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
  async created() {
    this.isEmptyAddress &&
      !this.FLASH_DICTIONARY[FlashId.LACKS_USER_DATA] &&
      this.ADD_FLASH({
        message: this.t('to_be_able'),
        type: FlashType.OK,
        id: FlashId.LACKS_USER_DATA,
      })

    const {
      SEX,
      FIRST_NAME,
      LAST_NAME,
      ADDRESS,
      CITY,
      COUNTRY,
      ZIP_CODE,
      CITIZENSHIP,
      BIRTH_DATE,
      BIRTH_MONTH,
      BIRTH_YEAR,
    } = this.name

    this.formData[SEX] = this.PERSONAL_INFO?.[SEX]
    this.formData[FIRST_NAME] = this.PERSONAL_INFO?.[FIRST_NAME]
    this.formData[LAST_NAME] = this.PERSONAL_INFO?.[LAST_NAME]
    this.formData[BIRTH_DATE] = this.PERSONAL_INFO?.[BIRTH_DATE]
    this.formData[BIRTH_MONTH] = this.PERSONAL_INFO?.[BIRTH_MONTH]
    this.formData[BIRTH_YEAR] = this.PERSONAL_INFO?.[BIRTH_YEAR]
    this.formData[ADDRESS] = this.PERSONAL_INFO?.[ADDRESS]
    this.formData[CITY] = this.PERSONAL_INFO?.[CITY]
    this.formData[ZIP_CODE] = this.PERSONAL_INFO?.[ZIP_CODE]

    const needsDefaultCountry =
      !this.PERSONAL_INFO?.[CITIZENSHIP] || !this.PERSONAL_INFO?.[COUNTRY]

    if (!needsDefaultCountry) {
      this.formData[CITIZENSHIP] = this.PERSONAL_INFO?.[CITIZENSHIP]
      this.formData[COUNTRY] = this.PERSONAL_INFO?.[COUNTRY]
    }

    try {
      const [{ data: countryList }, { ip }] = await Promise.all([
        MainApi.getCountryList(),
        ExternalApi.getIP(),
      ])

      const { data: countryData } = await MainApi.getCountryByIP({ ip })

      this.citizenshipList = countryList.items

      if (isNil(countryData)) {
        return
      }

      !this.PERSONAL_INFO?.[CITIZENSHIP] &&
        (this.formData[CITIZENSHIP] = countryData?.country_code)

      !this.PERSONAL_INFO?.[COUNTRY] &&
        (this.formData[COUNTRY] = countryData?.country_code)
    } catch (e) {
      console.log(e)
    }
  },
  beforeRouteLeave(to, from, next) {
    const needsRedirectoToChangePassword =
      this.MUST_CHANGE_PASSWORD && to?.name === RouteName.USER_CHANGE_PASSWORD

    if (
      this.isLogged &&
      this.isEmptyAddress &&
      !needsRedirectoToChangePassword
    ) {
      return
    }

    this.formData = { ...formData }
    next()
  },
}
</script>
